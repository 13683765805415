import request from '@/utils/request'

// 登录
export function CheckUser (data) {
  return request({
    url: '/Plat/CheckUser',
    method: 'post',
    data
  })
}

// 获取登记人员信息
export function GetOldManPage (query) {
  return request({
    url: '/OldMan/GetOldManPage',
    method: 'get',
    params: query
  })
}
// 获取我采集的老人信息列表
export function GetMyOldManPage (query) {
  return request({
    url: '/OldMan/GetMyOldManPage',
    method: 'get',
    params: query
  })
}
// 删除老人信息
export function DeleteOldMan (data) {
  return request({
    url: '/OldMan/DeleteOldMan',
    method: 'post',
    data
  })
}
// 添加修改老人信息
export function SaveOldMan (data) {
  return request({
    url: '/OldMan/SaveOldMan',
    method: 'post',
    data
  })
}
// 添获取组织机构树[小程序]
export function WeGetOrganTree (data) {
  return request({
    url: '/Organ/WeGetOrganTree',
    method: 'post',
    data
  })
}
// 获取指定组织机构的下级组织机构列表
export function GetChildOrgansNullToken (query) {
  return request({
    url: '/Organ/GetChildOrgansNullToken',
    method: 'get',
    params: query
  })
}
// 添加修改老人信息(自行添加,需审核)
export function WeSaveOldMan (data) {
  return request({
    url: '/OldMan/WeSaveOldMan',
    method: 'post',
    data
  })
}
// 保存老人信息
export function WeSaveOldManApply (data) {
  return request({
    url: '/OldmanApply/WeSaveOldManApply',
    method: 'post',
    data
  })
}
// 获取老人信息申请列表
export function WeGetOldManApply (params) {
  return request({
    url: 'OldmanApply/WeGetOldManApply',
    method: 'get',
    params
  })
}
// 获取字典别表
export function GetCodeEntrysList (params) {
  return request({
    url: 'Code/GetCodeEntrysList',
    method: 'get',
    params
  })
}
// 获取老人信息列表
export function WeGetOldManPage (params) {
  return request({
    url: 'OldMan/WeGetOldManPage',
    method: 'get',
    params
  })
}

// 统计当天当月工单数量
export function UnitWorkorderStat (params) {
  return request({
    url: 'PensionStat/UnitWorkorderStat',
    method: 'get',
    params
  })
}

// 统计近15天以来的工单数量
export function UnitWorkorderTrend (params) {
  return request({
    url: 'PensionStat/UnitWorkorderTrend',
    method: 'get',
    params
  })
}
// 服务同比
export function WorkOrderTrendOfDay (params) {
  return request({
    url: 'PensionStat/WorkOrderTrendOfDay',
    method: 'get',
    params
  })
}
// 服务次数
export function ServiceStat (params) {
  return request({
    url: 'PensionStat/ServiceStat',
    method: 'get',
    params
  })
}
// 服务次数
export function WorkOrderStat (params) {
  return request({
    url: 'PensionStat/WorkOrderStat',
    method: 'get',
    params
  })
}
// 服务机构
export function GetSerOrganList (params) {
  return request({
    url: 'SerOrgan/GetSerOrganList',
    method: 'get',
    params
  })
}
// 服务工单
export function GetWorkOrderPage (params) {
  return request({
    url: 'WorkOrder/GetWorkOrderPage',
    method: 'get',
    params
  })
}

// 服务工趋势
export function WOTrendOfSO (params) {
  return request({
    url: 'PensionStat/WOTrendOfSO',
    method: 'get',
    params
  })
}
// 老人社区分布
export function RegionStat (params) {
  return request({
    url: 'PensionStat/RegionStat',
    method: 'get',
    params
  })
}

// 老人年龄统计
export function OldManAgeStat (params) {
  return request({
    url: 'PensionStat/OldManAgeStat',
    method: 'get',
    params
  })
}
// 老人性别统计
export function OldManSexStat (params) {
  return request({
    url: 'PensionStat/OldManSexStat',
    method: 'get',
    params
  })
}
// 养老需求统计
export function OldManDemandStat (params) {
  return request({
    url: 'PensionStat/OldManDemandStat',
    method: 'get',
    params
  })
}