<template>
  <div style="background:#5681F2;height: 100%;display: inline-table;">
    <div style="width:90%;color:white;margin:0 auto;">
      <h2 style="text-align:center;">
        洛阳市新安县59岁以上老人基本信息采集
      </h2>
      <h3>
        一、采集范围及对象
      </h3>
      <p style="text-indent: 2em;">
        新安县户口年满59周岁及以上的老年人(1962年9月1日前出生)。
      </p>

      <h3>
        二、采集内容
      </h3>
      <p style="text-indent: 2em;">
        采集内容包括老年人的基本信息、身体状况、养老服务需求等。
      </p>

      <!-- <h3>
        三、采集时间
      </h3>
      <p style="text-indent: 2em;">
        2021年9月15日至2021年9月28日。
      </p> -->
    </div>
    <div style="width:90%;color:white;margin:0 auto;">
      <h3>
        <img :src="zuobiao"
          style="width:30px;height:30px; position: absolute;" />
        <p style="margin-left: 35px;">请选择所属乡镇/区</p>
      </h3>
      <div style="background:#80a0f5;padding:10px;display: flow-root;margin-bottom: 20px;">
        <div class="shequ"
          v-for="item in orgCodeList"
          :key="item.OCode">
          <router-link :to="'/shenbao/shenbaoAdd/' + item.OrganId ">
            {{item.OName}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WeGetOrganTree, GetChildOrgansNullToken } from "@/api/oldman";
import Vue from 'vue';
import { Toast } from 'vant';
export default {
  data () {
    return {
      listList: {},
      orgCodeList: {},
      zuobiao: "https://helpapi.dexian.ren//upload/images/2021/9/5ee96370-116.png",
    }
  },
  created () {
    this.getOrganTree();
    // this.getChildOrgans();
  },
  methods: {
    // 查询
    getOrganTree: function () {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...',
        forbidClick: true,
      });
      WeGetOrganTree().then((res) => {
        console.log(res.data.data[0].Children);
        if (res.data.code == 0) {
          this.listList = res.data.data[0].Children;
          for (let index = 0; index < this.listList.length; index++) {
            const element = this.listList[index];
            if (element.OCode == "410323") {
              Toast.clear();
              this.orgCodeList = element.Children
            }
          }
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    // 查询
    getChildOrgans: function () {
      GetChildOrgansNullToken({ organid: 224 }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.listList = res.data.data;
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
  }
}  
</script>
<style scoped>
.shequ {
  background: #5681f2;
  width: 43%;
  margin: 10px;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  color: white;
  float: left;
}
.shequ a {
  color: white;
}
</style>